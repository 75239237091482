.App {
    font-family: sans-serif;
    text-align: center;
    height: 100vh;
    width: 100vw;
    /* margin-top: 10vh; */
}

.down{
    z-index: 2;
    margin-left: 50vw;
    position: absolute;
}