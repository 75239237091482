.ant-layout-header {
    /* height: 12vh; */
    background-color: #fff;
}

.homepage_root .ant-card {
    display: grid;
    /* grid-template-rows: 33vh 7vh; */
    grid-template-rows: 26vh 4vh;
}

.homepage_root .ant-card-cover {
    /* display: flex; */
    display: grid;
    justify-content: center;
    align-items: center;
    height: 80%;
}

.homepage_root .ant-card-body {
    height: 50px;
    padding: 5px 0px;
    display: flex;
    justify-content: flex-start;
    align-content: flex-end;
}
/*************************/
*{
    margin: 0;
    padding: 0;
}

.divLayout {
    display: flex;
    justify-content: center;
}


.cardTitleLayout {
    color: #b7251e;
    text-align: center;
    font-size: 20px;
    margin: 0;
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-weight: 800;
}

.frameLayout {
    width: 360px;
    height: 360px;
    border: 2px solid #b7251e;
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: space-around; */
    background-color: white;
    margin: 2px 2px;
    position: relative;
    cursor: pointer;
    transition: all 0.3s;
}

.frameLayout:hover {
    box-shadow: 0 0 15px #c0c0c0;
    transform: translate(0, -3px)
}


.flexLayout {
    /* display: flex;
    justify-content: center; */
}

.flexTitle {
    /* display: flex;
    justify-content: start; */
}


.footerLayout {
    color: #b7251e;
    text-align: center;
    font-size: 20px;
    background: white;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 80px;
    line-height: 100%;
}

.gradualchange {
    background-image: linear-gradient(to top, white 0%, #bf2525 100%);
    width: 120%;
    height: 5px;
    transform: translate(-10%,-24px);
}

.flexLayout img {
    width: 45%;
    position: absolute;
    top: 0;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: auto;
}

.imgDZ {
    width: 85% !important;
}

.pLayout {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}